import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["player"]

  connect() {
    this.play = this.play.bind(this)
    this.stop = this.stop.bind(this)

    this.playerTarget.addEventListener('ready', () => {
      // Set to start and stop
      this.playerTarget.seek(0)
      this.playerTarget.stop()

      this.element.addEventListener('mouseenter', this.play)
      this.element.addEventListener('mouseleave', this.stop)
    })
  }

  disconnect() {
    this.element.removeEventListener('mouseenter', this.play)
    this.element.removeEventListener('mouseleave', this.stop)
  }

  play() {
    this.playerTarget.setDirection(1)
    this.playerTarget.play()
  }

  stop() {
    // Reverse the animation
    this.playerTarget.setDirection(-1)
    this.playerTarget.play()
  }
}
