import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  static values = {
    scrollThreshold: { type: Number, default: 10 }
  }

  connect() {
    // Set initial state
    this.isExpanded = true

    // Create GSAP timeline for animations
    this.timeline = gsap.timeline({ paused: true })
    this.setupAnimation()

    // Check initial scroll position and set correct state
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (scrollTop > this.scrollThresholdValue) {
      this.timeline.progress(1)
      this.isExpanded = false
    }

    // Add scroll listener
    this.scrollHandler = this.handleScroll.bind(this)
    window.addEventListener('scroll', this.scrollHandler, { passive: true })
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  setupAnimation() {
    this.timeline.to(this.element, {
      padding: 0,
      duration: 0.3,
      ease: "power2.out"
    })
  }

  handleScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (scrollTop > this.scrollThresholdValue && this.isExpanded) {
      // Remove outer padding
      this.timeline.play()
      this.isExpanded = false
    } else if (scrollTop <= this.scrollThresholdValue && !this.isExpanded) {
      // Restore outer padding
      this.timeline.reverse()
      this.isExpanded = true
    }
  }
}
